﻿export default class ProcessingResult<T> {
    Result: T;
    ErrorMessage: string;

    constructor(result: T, errorMessage: string) {
        this.Result = result;
        this.ErrorMessage = errorMessage;
    }

    public IsSuccess(): boolean {
        return this.ErrorMessage === null;
    }

    public IsFailed(): boolean {
        return this.ErrorMessage !== null;
    }

    static Ok<U>(result: U): ProcessingResult<U> {
        return new ProcessingResult<U>(result, null!);
    }

    static Error<U>(errorMessage: string): ProcessingResult<U> {
        return new ProcessingResult<U>(null!, errorMessage);
    }
}
