class DataSourceAccessDto {

    Id: number;
    Name: string;
    DataSourceType: number;
    Parameters: object;

    constructor(id: number, name: string, dataSourceType: number, parameters: object) {
        this.Id = id;
        this.Name = name;
        this.DataSourceType = dataSourceType;
        this.Parameters = parameters;
    }
}

export default DataSourceAccessDto;