import React from 'react';

import DashboardContent from '../../components/dashboard/DashboardContent';

import './dashboard.scss';

const Dashboard: React.FC = () => {

    return (
        <React.Fragment>
            <DashboardContent />
        </React.Fragment >
    )
}

export default Dashboard;