import PathSynchroMappingCapabilitiesDto from "../../../dtos/pathsynchromodule/PathSynchroMappingCapabilitiesDto";
import TransformationCapabilitiesDto from "../../../dtos/transformationmodule/TransformationCapabilitiesDto";

class GetPathSynchroCapabilitiesResult {
    MappingsCapabilities: PathSynchroMappingCapabilitiesDto[];
    TransformationsCapabilities: TransformationCapabilitiesDto[]; 

    constructor(mappingsCapabilities: PathSynchroMappingCapabilitiesDto[], transformationCapabilities: TransformationCapabilitiesDto[]) {
        this.MappingsCapabilities = mappingsCapabilities;
        this.TransformationsCapabilities = transformationCapabilities;
    }
}

export default GetPathSynchroCapabilitiesResult;
