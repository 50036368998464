import React, { useState, useEffect, useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import { ValidationRule } from 'devextreme-react/common';
import { Popup } from 'devextreme-react/popup';
import { Form, Label, FormRef, GroupItem, Item, SimpleItem, ButtonItem } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme-react/text-area';

import ActionType from '../../classes/consts/ActionType';

import DataSourceErrorForm from './DataSourceErrorForm';

import LabelTemplate from './LabelTemplate';

import DataSourceApi from "../../api/DataSourceApi";
import CustomerAgentApi from '../../api/CustomerAgentApi';

import DataSourceCrudLogic from '../../logics/datasourcemodule/DataSourceCrudLogic';
import DataSourceCredentialCrudLogic from '../../logics/datasourcecredentialmodule/DataSourceCredentialCrudLogic';

import SourceType from '../../consts/datasourcemodule/SourceType';

import DataSourceTypeListDto from '../../classes/dtos/datasourcemodule/DataSourceTypeListDto';
import CustomerAgentListDto from '../../classes/dtos/customeragentmodule/CustomerAgentListDto';
import DataSourceAuthenticationDetailDto from '../../classes/dtos/datasourcemodule/DataSourceAuthenticationDetailDto';

import DataSourceFormDto from '../../classes/dtos/datasourcemodule/DataSourceFormDto';

import './DataSourceForm.scss';

interface DataSourceFormProps {
    Action: string;
    Direction: string;
    DataSourceAdded(id: number): void;
}

export interface DataSourceFormHandle {
    Open: () => void;
}

const DataSourceForm: React.FC<DataSourceFormProps> = forwardRef<DataSourceFormHandle, DataSourceFormProps>(({ Action, Direction, DataSourceAdded }, ref) => {

    const position = { of: '#root' };

    const formRef = useRef<FormRef>(null);

    const [dataSourceTypes, setDataSourceTypes] = useState<DataSourceTypeListDto[] | null>(null);
    const [customerAgents, setCustomerAgents] = useState<CustomerAgentListDto[] | null>(null);
    const [withCustomerAgent, setWithCustomerAgent] = useState<boolean>(false);
    const [withIncludedCredentials, setWithIncludedCredentials] = useState<boolean>(false);
    const [withSeparatedCredentials, setWithSeparatedCredentials] = useState<boolean>(false);
    const [currentAuthenticationDetails, setCurrentAuthenticationDetails] = useState<DataSourceAuthenticationDetailDto[]>([]);

    const [dataSourceCredentialCrudLogic] = useState<DataSourceCredentialCrudLogic>(new DataSourceCredentialCrudLogic());
    const [dataSourceCrudLogic] = useState<DataSourceCrudLogic>(new DataSourceCrudLogic());

    const [dataSourceErrorIsOpen, setDataSourceErrorIsOpen] = useState<boolean>(false);

    const [dataSourceFormDto, setDataSourceFormDto] = useState<DataSourceFormDto>(new DataSourceFormDto(null, null, null, null, null));
    const [credentialValues, setCredentialValues] = useState<object>({});

    const [isOpen, setIsOpen] = useState(false);

    const [saveInProgress, setSaveInProgress] = React.useState(false);

    useImperativeHandle(ref, () => ({
        Open() {
            setIsOpen(true);
            setDataSourceFormDto(new DataSourceFormDto(null, null, null, null, null));
            setCredentialValues({});
        }
    }));

    useEffect(() => {
        let readable = Direction === "Source" ? true : null;
        let writable = Direction === "Target" ? true : null;

        DataSourceApi.GetDataSourceTypesByCustomerAsync(readable, writable)
            .then(getDataSourceTypesResult => {
                if (getDataSourceTypesResult.IsFailed()) {
                    // ERROR
                }
                setDataSourceTypes(getDataSourceTypesResult.Result.DataSourceTypes);
            });
    }, [Direction]);

    useEffect(() => {

        CustomerAgentApi.GetCustomerAgentsAsync()
            .then(allCustomerAgentsResult => {
                if (allCustomerAgentsResult.IsFailed()) {
                    // ERROR
                }
                setCustomerAgents(allCustomerAgentsResult.Result.CustomerAgents);
            });
    }, []);

    const dataSourceTypeEditorOptions = {
        items: dataSourceTypes,
        searchEnabled: false,
        displayExpr: "Name",
        onValueChanged: (e: any) => {
            let dataSourceType = e.value as DataSourceTypeListDto;
            setWithCustomerAgent(dataSourceType !== null && dataSourceType.ConnectorTypes.length === 1 && dataSourceType.ConnectorTypes[0].Id === 3);
            setWithIncludedCredentials(dataSourceType !== null && dataSourceType.SourceType === SourceType.WithIncludedCredentials);
            setWithSeparatedCredentials(dataSourceType !== null && dataSourceType.SourceType === SourceType.WithSeparatedCredentials);

            if (dataSourceType !== null && dataSourceType.Authentications.length > 0) {
                setCurrentAuthenticationDetails(dataSourceType.Authentications[0].Details);
                setCredentialValues({ Type: dataSourceType.Authentications[0].Key });
            }
            else {
                setCurrentAuthenticationDetails([]);
                setCredentialValues({});
            }
        }
    };

    const dataSourceNameEditorOptions = {
        valueChangeEvent: 'keyup',
        required: true
    };

    const customerAgentEditorOptions = {
        items: customerAgents,
        searchEnabled: false,
        displayExpr: "Name",
        onValueChanged: (e: any) => {
        }
    };

    const connectionStringEditorOptions = {
        valueChangeEvent: 'keyup',
        required: true
    };

    const updateValues = function (name: string, value: string) {
        var current = credentialValues;
        current[name] = value;
        setCredentialValues(current);
    }

    const dataSourceCredentialEditorOptions = {
        valueChangeEvent: 'keyup',
        required: true,
        onValueChanged: (e: any) => {
            const dataField = e.component.option("name");
            updateValues(dataField, e.value.toString());
        }
    }

    const validationRules: {
        dataSourceType: ValidationRule[],
        dataSourceName: ValidationRule[],
        customerAgent: ValidationRule[],
        connectionString: ValidationRule[],
        dataSourceCredential: ValidationRule[]
    } = {
        dataSourceType: [
            { type: 'required', message: 'Data source type is required.' },
        ],
        dataSourceName: [
            { type: 'required', message: 'Data source name is required.' },
        ],
        customerAgent: [
            { type: 'required', message: 'Customer agent is required.' },
        ],
        connectionString: [
            { type: 'required', message: 'Connection string is required.' },
        ],
        dataSourceCredential: [
            { type: 'required', message: 'Credential is required.' },
        ]
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        };
    }, []);

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        setSaveInProgress(true);

        if (dataSourceFormDto.DataSourceType === null) {
            alert("DataSourceType not set");
            setSaveInProgress(false);
            return;
        }

        if (dataSourceFormDto.DataSourceName === null) {
            alert("DataSourceName not set");
            setSaveInProgress(false);
            return;
        }

        if (withIncludedCredentials) {

            if (dataSourceFormDto.CustomerAgent === null) {
                alert("CustomerAgent not set");
                setSaveInProgress(false);
                return;
            }

            if (dataSourceFormDto.ConnectionString === null) {
                alert("ConnectionString not set");
                setSaveInProgress(false);
                return;
            }

            let createOrUpdateDataSourceStandardDatabaseResult = await dataSourceCrudLogic.CreateOrUpdateDataSourceStandardDatabaseAsync(dataSourceFormDto.CustomerAgent.Id, dataSourceFormDto.DataSourceType.Id, dataSourceFormDto.DataSourceName, dataSourceFormDto.ConnectionString);

            if (createOrUpdateDataSourceStandardDatabaseResult.IsFailed()) {
                alert(createOrUpdateDataSourceStandardDatabaseResult.ErrorMessage);
                setSaveInProgress(false);
                return;
            }

            if (!createOrUpdateDataSourceStandardDatabaseResult.Result.Ok) {
                setDataSourceErrorIsOpen((previous) => true);
                setSaveInProgress(false);
                return;
            }

            DataSourceAdded(dataSourceCrudLogic.CurrentDataSourceId);
            setIsOpen(false);
            setSaveInProgress(false);
            return;
        }

        if (withSeparatedCredentials) {
            let createOrUpdateDataSourceAccessResult = await dataSourceCredentialCrudLogic.CreateOrUpdateDataSourceAccessAsync(dataSourceFormDto.DataSourceName, dataSourceFormDto.DataSourceType.Id, credentialValues);

            if (createOrUpdateDataSourceAccessResult.IsFailed()) {
                alert(createOrUpdateDataSourceAccessResult.ErrorMessage);
                setSaveInProgress(false);
                return;
            }

            if (!createOrUpdateDataSourceAccessResult.Result.Ok) {
                setDataSourceErrorIsOpen((previous) => true);
                setSaveInProgress(false);
                return;
            }

            if (dataSourceCredentialCrudLogic.CurrentDataSourceAccessId === null) {
                alert("CurrentDataSourceAccessId not set");
                setSaveInProgress(false);
                return;
            }

            let createOrUpdateDataSourceWithCredentialResult = await dataSourceCrudLogic.CreateOrUpdateDataSourceWithCredentialAsync(dataSourceFormDto.DataSourceType.Id, withCustomerAgent ? 3 : 2, dataSourceFormDto.DataSourceName, null, dataSourceCredentialCrudLogic.CurrentDataSourceAccessId);

            if (createOrUpdateDataSourceWithCredentialResult.IsFailed()) {
                alert(createOrUpdateDataSourceWithCredentialResult.ErrorMessage);
                setSaveInProgress(false);
                return;
            }

            if (!createOrUpdateDataSourceWithCredentialResult.Result.Ok) {
                setDataSourceErrorIsOpen((previous) => true);
                setSaveInProgress(false);
                return;
            }

            DataSourceAdded(dataSourceCrudLogic.CurrentDataSourceId);
            setIsOpen(false);
            setSaveInProgress(false);
            return;
        }
    };

    const close = () => {
        setIsOpen(false);
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'outlined' as ButtonType,
        useSubmitBehavior: false,
        width: '100%',
        onClick: close
    };

    const confirmButtonOptions = {
        text: 'Confirm',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%'
    };

    function getTitle() {
        switch (Action) {
            case ActionType.Create: return "Create a dataSource";
            case ActionType.Update: return "Update a dataSource";
            case ActionType.Delete: return "Delete a dataSource";
            default: return "???";
        }
    }

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={isOpen}
                onHiding={closeDialog}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title={getTitle()}
                container=".dx-viewport"
                width={600}
                height="auto"
            >
                <form onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={dataSourceFormDto}
                        showColonAfterLabel={true}
                        showValidationSummary={false}
                        validationGroup="dataSourceDtoData"
                        colCount={2}
                        disabled={saveInProgress}
                    >
                        <GroupItem colSpan={2}>
                            <SimpleItem dataField="DataSourceType" editorType="dxSelectBox" editorOptions={dataSourceTypeEditorOptions} validationRules={validationRules.dataSourceType}>
                                <Label render={LabelTemplate('product', 'Data source type')} />
                            </SimpleItem>
                            <SimpleItem dataField="DataSourceName" editorOptions={dataSourceNameEditorOptions} validationRules={validationRules.dataSourceName}>
                                <Label render={LabelTemplate('rename', 'Data source name')} />
                            </SimpleItem>
                            <Item dataField="CustomerAgent" editorType="dxSelectBox" editorOptions={customerAgentEditorOptions} validationRules={validationRules.customerAgent} visible={withCustomerAgent}>
                                <Label render={LabelTemplate('floppy', 'Customer agent')} />
                            </Item>
                        </GroupItem>
                        <GroupItem visible={withIncludedCredentials} colSpan={2}>
                            <Item dataField="ConnectionString" editorOptions={connectionStringEditorOptions} validationRules={validationRules.connectionString} visible={withIncludedCredentials}>
                                <Label render={LabelTemplate('login', 'Connection string')} />
                            </Item>
                        </GroupItem>
                        <GroupItem visible={withSeparatedCredentials} colSpan={2}>
                            {currentAuthenticationDetails.map((e, index) =>
                            (
                                <SimpleItem dataField={e.Name} editorOptions={dataSourceCredentialEditorOptions} validationRules={validationRules.dataSourceCredential}>
                                    <Label render={LabelTemplate('rename', e.Label)} />
                                </SimpleItem>
                            ))}
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={cancelButtonOptions} />
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={confirmButtonOptions} />
                        </GroupItem>
                    </Form>
                </form>
            </Popup>
            <LoadPanel
                position={position}
                shadingColor="rgba(0,0,0,0.4)"
                visible={saveInProgress}
            />
            <DataSourceErrorForm IsOpen={dataSourceErrorIsOpen} SetIsOpen={setDataSourceErrorIsOpen} ></DataSourceErrorForm>
        </React.Fragment>
    );
});

export default DataSourceForm;