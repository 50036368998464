import React, { useRef } from 'react';

import DataSourceSelectionForm, { DataSourceSelectionFormHandle } from '../datasource-selection/DataSourceSelectionForm';

import DataSourceSelectionDto from '../../classes/dtos/datasourcemodule/DataSourceSelectionDto';

interface DataSourceSelectionProps {
    DataSourceId: number;
    DataSourceLabel: string;
    SetDataSourceSelected(value: DataSourceSelectionDto): void;
}

const DataSourceSelection: React.FC<DataSourceSelectionProps> = ({ DataSourceId, DataSourceLabel, SetDataSourceSelected }) => {

    const dataSourceSelectionFormHandleRef = useRef<DataSourceSelectionFormHandle>(null);

    const handleSetDataSourceSelected = (dataSourceSelectionDto: DataSourceSelectionDto) => {
        SetDataSourceSelected(dataSourceSelectionDto);
    };

    function selectDataSource(): void {
        if (dataSourceSelectionFormHandleRef.current !== null) {
            dataSourceSelectionFormHandleRef.current.Open(DataSourceId);
        }
    };

    return (
        <React.Fragment>
            <div style={{ width: 440, padding: '20px', background: '#1A1A20', borderRadius: 16, border: '1px #3D3D46 solid', justifyContent: 'flex-start', alignItems: 'center', gap: '32px', display: 'inline-flex' }}>
                <div style={{ flexDirection: 'column', justifyContent: 'left', alignItems: 'left', gap: 12, display: 'inline-flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <div style={{ color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>1. Select your source</div>
                        {DataSourceId > 0 && <img src="svgs/circle.svg" alt="circle" />}
                    </div>
                    <div style={{ color: '#9393A2', fontSize: 12, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '15.60px', letterSpacing: 0.24, wordWrap: 'break-word', alignSelf: 'flex-start' }}>
                        {DataSourceId > 0 ? DataSourceLabel + " selected" : "No source selected"}
                    </div>
                </div>
                {DataSourceId <= 0 ?
                    <img src="svgs/plussource.svg" alt="plus" style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={selectDataSource} /> :
                    <div style={{ marginLeft: 'auto', cursor: 'pointer', gap: 6, display: 'flex' }} onClick={selectDataSource} >
                        <div style={{ color: '#E3E3E8', fontSize: 14, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '21px', letterSpacing: 0.28, wordWrap: 'break-word' }}>Edit</div>
                        <img src="svgs/edit.svg" alt="circle" />
                    </div>}
            </div>
            <DataSourceSelectionForm ref={dataSourceSelectionFormHandleRef} Direction="Source" SetDataSourceSelected={handleSetDataSourceSelected} />
        </React.Fragment>
    );
}

export default DataSourceSelection;