import React, { createContext } from 'react';
import { HubConnection } from '@microsoft/signalr';

interface NotificationContextType {
    notificationConnection: HubConnection;
    sharedVariable: string;
    setSharedVariable: React.Dispatch<React.SetStateAction<string>>;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export default NotificationContext;
