import React from 'react';
import { DataGridTypes } from 'devextreme-react/data-grid';

import FieldType from '../../consts/datasourcemodule/FieldType';

import FieldTypeReferenceDto from '../../classes/dtos/FieldTypeReferenceDto';
import SynchroFieldMappingDto from '../../classes/dtos/pathsynchromodule/SynchroFieldMappingDto';

const getTagColor = (type: FieldTypeReferenceDto): string => {
    switch (type.Type) {
        case 0:
            return '#5A5A65';
        case FieldType.String:
            return '#00BBEE';
        case 8:
            return '#9A4AFF';
        case 3000:
            return '#0F7DFF';
    }
    return '#9A4AFF';
}

const getTextColor = (type: FieldTypeReferenceDto): string => {
    switch (type.Type) {
        case 0:
            return '#9393A2';
        case FieldType.String:
            return 'white';
        case 8:
            return 'white';
        case 3000:
            return 'white';
            
    }
    return 'white';
}


const TargetFieldTypeCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {

    var synchroFieldMappingDto = cellData.data as SynchroFieldMappingDto;
    let fieldTypeReferenceDto = synchroFieldMappingDto.Type;
    let tagColor = getTagColor(fieldTypeReferenceDto);
    let textColor = getTextColor(fieldTypeReferenceDto);

    return (
        <div style={{ width: 'auto', height: 22, paddingLeft: 10, paddingRight: 10, paddingTop: 2, paddingBottom: 2, background: tagColor, borderRadius: 16, justifyContent: 'center', alignItems: 'center', gap: 6, display: 'inline-flex' }}>
            <div style={{ textAlign: 'center', color: textColor, fontSize: 10, fontFamily: 'Manrope', fontWeight: '500', textTransform: 'uppercase', lineHeight: 13, letterSpacing: 1, wordWrap: 'break-word' }}>{fieldTypeReferenceDto.TypeLabel}</div>
        </div>
    )
};

export default TargetFieldTypeCell;
