import React from 'react';
//import Sparkline, { Size, Tooltip } from 'devextreme-react/sparkline';
import { DataGridTypes } from 'devextreme-react/data-grid';

const LogoCell = (cellData: DataGridTypes.ColumnCellTemplateData) => (
    <div
        style={{
            width: '23px',
            height: '23px',
            backgroundColor: 'white',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px'
        }}
    >
        <img
            src={`data:image/png;base64,${cellData.data.Logo}`}
            style={{
                width: '16px',
                height: '16px'
            }}
            alt="Logo"
        />
    </div>
);

export default LogoCell;
