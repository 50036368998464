import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENTID ?? "EMPTY",
        authority: process.env.REACT_APP_B2C_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_B2C_KNOWAUTHORITIES ?? "EMPTY"],
        redirectUri: process.env.REACT_APP_WEBAPP_URL, 
        navigateToLoginRequestUrl: true 
    }
}

export const apiScopes: string[] = [process.env.REACT_APP_B2C_APISCOPES ?? "EMPTY"];