import React, { useCallback, useState, useEffect, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import { ValidationRule } from 'devextreme-react/common';
import { Popup } from 'devextreme-react/popup';
import { Form, Label, FormRef, GroupItem, SimpleItem, ButtonItem } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';
import 'devextreme-react/text-area';

import LabelTemplate from './LabelTemplate';

import DatabaseReferenceLogic from "./../../logics/datastructuremodule/DatabaseReferenceLogic";

import DatabaseReferenceIdAndKeyDto from '../../classes/dtos/DatabaseReferenceIdAndKeyDto';

import DatabaseSelectionDto from '../../classes/dtos/datastructuremodule/DatabaseSelectionDto';

import './DatabaseSelectionForm.scss';

interface DatabaseSelectionFormProps {
    DataSourceId: number;
    SetDatabaseSelected(value: DatabaseSelectionDto): void;
}

export interface DatabaseSelectionFormHandle {
    Open: (databaseReferenceId: number) => void;
}

const DatabaseSelectionForm: React.FC<DatabaseSelectionFormProps> = forwardRef<DatabaseSelectionFormHandle, DatabaseSelectionFormProps>(({ DataSourceId, SetDatabaseSelected }, ref) => {

    const [databaseSelectionDto, setDatabaseSelectionDto] = useState<DatabaseSelectionDto>(new DatabaseSelectionDto(null));

    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        Open(databaseReferenceId: number) {
            if (databases !== null) {
                let current = databases.find((e) => e.Id === databaseReferenceId) ?? null;
                setDatabaseSelectionDto(new DatabaseSelectionDto(current));
            }
            setIsOpen(true);
        }
    }));

    const formRef = useRef<FormRef>(null);

    const [databases, setDatabases] = useState<DatabaseReferenceIdAndKeyDto[] | null>(null);

    const databaseEditorOptions = {
        items: databases,
        searchEnabled: false,
        displayExpr: "Name"
    };

    const databasesLoad = useCallback(async () => {
        if (DataSourceId > 0) {
            DatabaseReferenceLogic.GetDatabaseReferencesAsync(DataSourceId, true)
                .then(getDatabaseReferencesResult => {
                    var allDatabases = getDatabaseReferencesResult.Result.Databases
                        .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);
                    setDatabases(allDatabases);
                });
        }
    }, [DataSourceId]);

    useEffect(() => {
        databasesLoad()
    }, [databasesLoad]);

    const validationRules: {
        database: ValidationRule[]
    } = {
        database: [
            { type: 'required', message: 'Database is required.' },
        ]
    };

    function cancel(): void {
        setIsOpen(false);
    };

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        }
    }, []);

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        SetDatabaseSelected(databaseSelectionDto);
        setIsOpen(false);
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'outlined' as ButtonType,
        useSubmitBehavior: false,
        width: '100%',
        onClick: cancel
    };

    const confirmButtonOptions = {
        text: 'Confirm',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%'
    };

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={isOpen}
                onHiding={cancel}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title="Choose your database"
                container=".dx-viewport"
                width={600}
                height="auto"
            >
                <form onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={databaseSelectionDto}
                        showColonAfterLabel={true}
                        showValidationSummary={false}
                        validationGroup="databaseSelectionDtoData"
                        colCount={2}
                    >
                        <GroupItem colSpan={2}>
                            <SimpleItem dataField="Database" editorType="dxSelectBox" editorOptions={databaseEditorOptions} validationRules={validationRules.database}>
                                <Label render={LabelTemplate('product')} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem colSpan={2}>
                            <div style={{ width: '100%', height: 36, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                    <div style={{ color: '#9393A2', fontSize: 10, fontFamily: 'Manrope', fontWeight: '600', textTransform: 'uppercase', lineHeight: '13px', letterSpacing: 1, wordWrap: 'break-word' }}>OR</div>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                </div>
                            </div>
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={cancelButtonOptions} />
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={confirmButtonOptions} />
                        </GroupItem>
                    </Form>
                </form>
            </Popup>
        </React.Fragment>
    );
});

export default DatabaseSelectionForm;