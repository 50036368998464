import React from 'react';
import { ScrollView } from 'devextreme-react/scroll-view';

import './explore.scss';

export default function Explore() {
    return (
        <div className="settings-container">
            <ScrollView>
                <div className="under-construction">
                    <img src="svgs/underconstruction.svg" alt="underconstruction" className="under-construction-image" />
                </div>
            </ScrollView>
        </div>
    );
}