class UserData {

    UserId: number;
    CustomerId: number;

    constructor(userId: number, customerId: number) {
        this.UserId = userId;
        this.CustomerId = customerId;
    }
}

export default UserData;
