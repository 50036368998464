import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DataChange } from 'devextreme/common/grids';
import Tabs, { Item } from 'devextreme-react/tabs';
import { useNavigate, useParams } from 'react-router-dom';

import { Toast } from 'devextreme-react/toast';

import { Button, TextBox } from 'devextreme-react';

import ChangeInProgressForm from '../../components/change-in-progress-form/ChangeInProgressForm';

import SourceSelection from '../../components/source-selection/SourceSelection';
import SynchroMappingFields, { SynchroMappingFieldsHandle } from '../../components/synchro-mapping-fields/SynchroMappingFields';
import SynchroSchedule from '../../components/synchro-schedule/SynchroSchedule';

import PathSynchroApi from '../../api/PathSynchroApi';

import GetPathSynchroDetailResult from '../../classes/api/result/pathsynchromodule/GetPathSynchroDetailResult';
import PathSynchroMappingDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingDto';

import TableMappingReadDto from '../../classes/dtos/pathcommonmodule/TableMappingReadDto';
import SynchroFieldMappingDto from '../../classes/dtos/pathsynchromodule/SynchroFieldMappingDto';

import './path-synchro.scss';

export default function PathSynchro() {

    const { id } = useParams();

    const navigate = useNavigate();

    const pathId = Number.parseInt(id ?? "0");

    const synchroMappingFieldsRef = useRef<SynchroMappingFieldsHandle>(null);

    const [pathSynchroDetail, setPathSynchroDetail] = useState<GetPathSynchroDetailResult | null>(null);

    const [currentTab, setCurrentTab] = useState<number>(0);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [changeInprogress, setChangeInprogress] = useState<boolean>(false);

    const [editNameInprogress, setEditNameInprogress] = useState<string>(null);

    const dataChangesRef = useRef<DataChange[]>([]);

    const pathSynchroMappingDtosRef = useRef<PathSynchroMappingDto[]>([]);

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: '',
        withRedirect: false
    } as {
        isVisible: boolean,
        type: 'info' | 'error' | 'success',
        message: string,
        withRedirect: boolean
    });

    useEffect(() => {
        PathSynchroApi.GetPathSynchroDetailAsync(pathId)
            .then(detail => {
                setPathSynchroDetail(detail.Result as GetPathSynchroDetailResult);
            });
    }, [pathId]);

    function handleNameChange() {
        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }
            setChangeInprogress(true);
            previous.Name = editNameInprogress;
            setEditNameInprogress(null);
            return previous;
        });
    }

    const handleDataChanges = useCallback((changes: DataChange[], origin: SynchroFieldMappingDto[]) => {
        dataChangesRef.current = changes;

        let result = new Array<PathSynchroMappingDto>();

        origin.forEach((e: SynchroFieldMappingDto) => result.push(new PathSynchroMappingDto(e.IsKey, e.SourceFieldReferenceId ?? 0, e.TargetFieldReferenceId, e.TransformationType ?? 0, e.TransformationParameters ?? "")));

        changes.forEach((e: DataChange) => {

            let row = result.find((x) => x.TargetFieldReferenceId === e.key) ?? null;

            if (row !== null) {
                if (e.data.IsKey !== undefined) {
                    row.IsKey = e.data.IsKey;
                }
                if (e.data.SourceFieldReferenceId !== undefined) {
                    row.SourceFieldReferenceId = e.data.SourceFieldReferenceId;
                }
                if (e.data.TransformationType !== undefined) {
                    row.TransformationType = e.data.TransformationType;
                }
                if (e.data.TransformationParameters !== undefined) {
                    row.TransformationParameters = e.data.TransformationParameters;
                }
            }
        });

        pathSynchroMappingDtosRef.current = result.filter((e: PathSynchroMappingDto) => e.SourceFieldReferenceId !== null && e.SourceFieldReferenceId !== 0);

    }, []);

    const handleTableMappingSourceChange = useCallback((tableMappingReadDto: TableMappingReadDto) => {
        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            return new GetPathSynchroDetailResult(
                previous.Id,
                previous.Name,
                tableMappingReadDto,
                previous.Target,
                previous.Mappings,
                previous.Schedule);
        });

    }, []);

    const handleTableMappingTargetChange = useCallback((tableMappingReadDto: TableMappingReadDto) => {
        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            return new GetPathSynchroDetailResult(
                previous.Id,
                previous.Name,
                previous.Source,
                tableMappingReadDto,
                previous.Mappings,
                previous.Schedule);
        });

    }, []);

    function save(publish: boolean): void {
        if (pathSynchroDetail === null || pathSynchroDetail.Source === null || pathSynchroDetail.Target === null) {
            alert("ERROR");
            return;
        }

        setPathSynchroDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            return new GetPathSynchroDetailResult(
                previous.Id,
                previous.Name,
                previous.Source,
                previous.Target,
                pathSynchroMappingDtosRef.current,
                previous.Schedule);
        });

        PathSynchroApi.UpdatePathSynchroDetailAsync(
            pathId,
            pathSynchroDetail.Name,
            pathSynchroMappingDtosRef.current,
            pathSynchroDetail.Source.DataSourceId > 0 ? pathSynchroDetail.Source.DataSourceId : null,
            pathSynchroDetail.Source.DatabaseReferenceId > 0 ? pathSynchroDetail.Source.DatabaseReferenceId : null,
            pathSynchroDetail.Source.TableReferenceId > 0 ? pathSynchroDetail.Source.TableReferenceId : null,
            pathSynchroDetail.Target.DataSourceId > 0 ? pathSynchroDetail.Target.DataSourceId : null,
            pathSynchroDetail.Target.DatabaseReferenceId > 0 ? pathSynchroDetail.Target.DatabaseReferenceId : null,
            pathSynchroDetail.Target.TableReferenceId > 0 ? pathSynchroDetail.Target.TableReferenceId : null,
            pathSynchroDetail.Schedule,
            publish)
            .then((updateResult) => { if (updateResult.IsFailed()) { alert("ERROR"); } });

        synchroMappingFieldsRef.current?.Save();

        setChangeInprogress(false);

        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: 'success',
            message: publish ? 'Path saved and published' : 'Path saved as draft',
            withRedirect: publish
        });

    }

    const onHiding = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });

        if (toastConfig.withRedirect) {
            navigate("/paths");
        }

    }, [toastConfig, navigate]);

    function cancel(): void {

        if (changeInprogress) {
            setIsOpen(true);
        }
        else {
            navigate("/paths");
        }
    }

    function cancelFromModal(): void {

        setIsOpen(false);
        navigate("/paths");
    }

    function saveDraftFromModal(): void {

        setIsOpen(false);
        save(false);
        navigate("/paths");
    }

    function changePage(index: number): void {
        setCurrentTab(index);
    };

    function back() {
        setCurrentTab(currentTab - 1);
    }

    function next() {
        setCurrentTab(currentTab + 1);
    }

    if (pathSynchroDetail === null) {
        return (<div>Waiting</div>);
    }

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '100%', padding: 24, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                <div style={{ alignSelf: 'stretch', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex' }}>
                    <div style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex', minHeight: '49px' }}>
                        {editNameInprogress === null ?
                            <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'flex' }}>
                                <div style={{ color: '#E3E3E8', fontSize: 24, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '36px', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                                    {pathSynchroDetail?.Name ?? "???"}
                                </div>
                                <img src="svgs/edit.svg" alt="circle" style={{ cursor: 'pointer' }} onClick={() => setEditNameInprogress(pathSynchroDetail?.Name ?? "???")} />
                            </div> :
                            <div style={{ width: '50%', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'flex' }}>
                                <TextBox style={{ width: '50%' }} defaultValue={pathSynchroDetail?.Name ?? "???"} onValueChange={(e) => setEditNameInprogress(e)} />
                                <Button icon="check" onClick={() => handleNameChange()} />
                                <Button icon="close" onClick={() => setEditNameInprogress(null)} />
                            </div>}
                        <Button text="Cancel" type="normal" stylingMode="text" onClick={() => cancel()} />
                    </div>
                    <Tabs width='100%' stylingMode='secondary' onSelectedIndexChange={changePage} selectedIndex={currentTab} >
                        <Item text="Source">
                        </Item>
                        <Item text="Target">
                        </Item>
                        <Item text="Mapping">
                        </Item>
                        <Item text="Schedule">
                        </Item>
                        <Item text="Checking">
                        </Item>
                    </Tabs>
                    {(currentTab === 0) ? <SourceSelection TableMapping={pathSynchroDetail.Source ?? null} SetTableMapping={handleTableMappingSourceChange} /> : ""}
                    {(currentTab === 1) ? <SourceSelection TableMapping={pathSynchroDetail.Target ?? null} SetTableMapping={handleTableMappingTargetChange} /> : ""}
                    {(currentTab === 2) ? <SynchroMappingFields ref={synchroMappingFieldsRef} Source={pathSynchroDetail.Source ?? null} Target={pathSynchroDetail.Target ?? null} Mappings={pathSynchroDetail?.Mappings ?? []} MappingChanges={dataChangesRef.current} SetMappingChanges={handleDataChanges} /> : ""}
                    {(currentTab === 3) ? <SynchroSchedule PathId={pathId} /> : ""}
                </div>
                <div style={{ width: '100%', height: 64, position: 'absolute', bottom: 64, left: 0, display: 'flex', justifyContent: 'space-between', padding: '16px 24px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button icon='back' text="Back to previous step" type="normal" stylingMode="text" onClick={() => back()} disabled={currentTab === 0} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                        <Button text="Save as draft" type="normal" stylingMode="text" onClick={() => save(false)} />
                        {currentTab === 2 ?
                            <Button text="Save and publish" type="default" stylingMode="contained" onClick={() => save(true)} /> :
                            <Button text="Continue to next step" type="default" stylingMode="contained" onClick={() => next()} />}
                    </div>
                </div>
            </div>
            <ChangeInProgressForm IsOpen={isOpen} SetIsOpen={setIsOpen} Ok={() => saveDraftFromModal()} Cancel={() => cancelFromModal()} />
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={1000}
            />
        </React.Fragment>
    )
}
