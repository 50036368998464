import React, { useState, useEffect, useCallback, useRef, useImperativeHandle, forwardRef, Fragment } from 'react';
import { DataGrid, Column, FilterRow, Scrolling, DataGridRef, Editing, Lookup, DataGridTypes } from 'devextreme-react/data-grid';
import { DataChange } from 'devextreme/common/grids';
import { SelectBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import GlobalLoader from '../loader/GlobalLoader';

import FieldType from '../../consts/datasourcemodule/FieldType';

import TargetFieldTypeCell from './TargetFieldTypeCell';

import PathSynchroApi from '../../api/PathSynchroApi';

import FieldReferenceLogic from '../../logics/datastructuremodule/FieldReferenceLogic';
import { TransformationLogic } from '../../logics/transformationmodule/TransformationLogic';

import PathSynchroMappingCapabilitiesDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingCapabilitiesDto';
import FieldReferenceIdAndKeyDto from '../../classes/dtos/FieldReferenceIdAndKeyDto';
import FieldTypeReferenceDto from '../../classes/dtos/FieldTypeReferenceDto';
import PathSynchroMappingDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingDto';
import TransformationTypeDto from '../../classes/dtos/pathcommonmodule/TransformationTypeDto';
import TransformationCapabilitiesDto from '../../classes/dtos/transformationmodule/TransformationCapabilitiesDto';

import SynchroFieldMappingDto from '../../classes/dtos/pathsynchromodule/SynchroFieldMappingDto';

import './FieldGrid.css';
import './EditTransformationCell.css';

interface FieldGridProps {
    SourceDataSourceId: number;
    SourceTableReferenceId: number;
    TargetDataSourceId: number;
    TargetTableReferenceId: number;
    Mappings: PathSynchroMappingDto[] | null;
    MappingChanges: DataChange[] | undefined;
    SetMappingChanges(changes: DataChange[], origin: SynchroFieldMappingDto[]): void;
}

export interface FieldGridHandle {
    Save: () => void;
}

const FieldGrid: React.FC<FieldGridProps> = forwardRef<FieldGridHandle, FieldGridProps>(({ SourceDataSourceId, SourceTableReferenceId, TargetDataSourceId, TargetTableReferenceId, Mappings, MappingChanges, SetMappingChanges }, ref) => {

    const dataGridRef = useRef<DataGridRef>(null);

    const [sourceFields, setSourceFields] = useState<FieldReferenceIdAndKeyDto[]>([]);
    const [targetFields, setTargetFields] = useState<FieldReferenceIdAndKeyDto[]>([]);
    const [capabilities, setCapabilities] = useState<PathSynchroMappingCapabilitiesDto[]>([]);
    const [transformationsCapabilities, setTransformationsCapabilities] = useState<TransformationCapabilitiesDto[]>([]);
    const [transformationTypes] = useState<TransformationTypeDto[]>([
        new TransformationTypeDto(0, "Direct"),
        new TransformationTypeDto(1, "Link"),
        new TransformationTypeDto(2, "Format (Direct)"),
        new TransformationTypeDto(3, "Format (Configurable)"),
        new TransformationTypeDto(1001, "Concatenate"),
        new TransformationTypeDto(1002, "First"),
        new TransformationTypeDto(1003, "Last")
    ]);

    const [mapping, setMapping] = useState<SynchroFieldMappingDto[]>([]);

    type UpdateObject = { [key: string]: any };

    function updateObjectInPlace(target: UpdateObject, updates: UpdateObject): void {
        Object.assign(target, updates);
    }

    useImperativeHandle(ref, () => ({
        Save() {
            if (dataGridRef.current) {
                dataGridRef.current.instance().saveEditData();
            }
        }
    }));

    const dataLoad = useCallback(async () => {

        if (SourceDataSourceId > 0 && SourceTableReferenceId > 0 && TargetDataSourceId > 0 && TargetTableReferenceId > 0) {

            var getFieldReferencesSourceResult = await FieldReferenceLogic.GetFieldReferencesAsync(SourceDataSourceId, SourceTableReferenceId, false);
            var allFieldsSource = getFieldReferencesSourceResult.Result.Fields
                .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);
            setSourceFields(allFieldsSource);

            var getFieldReferencesTargetResult = await FieldReferenceLogic.GetFieldReferencesAsync(TargetDataSourceId, TargetTableReferenceId, false);
            var allFieldsTarget = getFieldReferencesTargetResult.Result.Fields
                .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);
            setTargetFields(allFieldsTarget);

            var getPathSynchroCapabilitiesResult = await PathSynchroApi.GetPathSynchroCapabilitiesAsync(SourceTableReferenceId, TargetTableReferenceId);
            setCapabilities(getPathSynchroCapabilitiesResult.Result.MappingsCapabilities);
            setTransformationsCapabilities(getPathSynchroCapabilitiesResult.Result.TransformationsCapabilities);

            setMapping(allFieldsTarget.map((e) => {
                let readMapping = Mappings?.find(x => x.TargetFieldReferenceId === e.Id) ?? null;
                if (readMapping === null) {
                    return new SynchroFieldMappingDto(false, null, null, null, e.Id, e.Name, e.Type);
                } else {
                    return new SynchroFieldMappingDto(readMapping.IsKey, readMapping.SourceFieldReferenceId, readMapping.TransformationType, readMapping.TransformationParameters, e.Id, e.Name, e.Type);
                }
            }));
        }
    }, [SourceDataSourceId, SourceTableReferenceId, TargetDataSourceId, TargetTableReferenceId]);

    useEffect(() => {
        dataLoad()
    }, [dataLoad]);

    // Create a custom data source using CustomStore
    const customDataSource = new CustomStore({
        key: 'TargetFieldReferenceId',
        load: () => {
            return mapping || [];
        },
        update: (key: any, values: SynchroFieldMappingDto): any => {

            let row = mapping.find(e => e.TargetFieldReferenceId === key) ?? null;

            if (row !== null) {
                updateObjectInPlace(row, values);
            }
        },
        onLoaded: (): any => {
            if (dataGridRef.current?.instance() !== undefined) {
                dataGridRef.current.instance().option('editing.changes', MappingChanges);
            }
        }
    });

    if (!sourceFields || !targetFields || !capabilities) {
        return <GlobalLoader />
    }

    const calculateLookupFields = (rowData: SynchroFieldMappingDto) => {
        let fields = new Array<FieldReferenceIdAndKeyDto>();
        sourceFields.forEach((sourceField) => {
            var currentCapabilities = capabilities.find((e) => e.TargetFieldId === rowData.TargetFieldReferenceId) ?? null;
            if (currentCapabilities !== null) {
                var source = currentCapabilities.Capabilities.find((e) => e.SourceFieldId === sourceField.Id) ?? null;
                if (source !== null && source.TransformationTypes.length > 0) {
                    fields.push(sourceField);
                }
            }
        });
        return fields;
    };

    const calculateLookupTransformationTypes = (rowData: SynchroFieldMappingDto) => {

        let transformations = new Array<TransformationTypeDto>();

        if (rowData.SourceFieldReferenceId !== null) {
            var currentCapabilities = capabilities.find((e) => e.TargetFieldId === rowData.TargetFieldReferenceId) ?? null;
            if (currentCapabilities !== null) {
                var source = currentCapabilities.Capabilities.find((e) => e.SourceFieldId === rowData.SourceFieldReferenceId) ?? null;
                if (source !== null && source.TransformationTypes.length > 0) {
                    transformationTypes.forEach((transformationType) => {
                        var found = source?.TransformationTypes.find((x) => x === transformationType.Id) ?? null;
                        if (found !== null) {
                            transformations.push(transformationType);
                        }
                    });
                }
            }
        }

        return transformations;
    };

    const handleOnEditorPreparing = (e: any) => {
        if (e.parentType === 'dataRow' && e.dataField === 'SourceFieldReferenceId') {
            e.editorOptions.dataSource = calculateLookupFields(e.row.data);

            e.editorOptions.onValueChanged = (args: any) => {
                e.setValue(args.value);

                let current = dataGridRef.current;

                if (current !== null) {
                    const nextColumnIndex = 2;
                    const rowIndex = e.row.rowIndex;
                    current.instance().focus(current.instance().getCellElement(rowIndex, nextColumnIndex));
                    current.instance().repaintRows(rowIndex);
                    current.instance().editCell(rowIndex, nextColumnIndex);
                }
            };
        }

        if (e.parentType === 'dataRow' && e.dataField === 'TransformationType') {
            e.editorOptions.dataSource = calculateLookupTransformationTypes(e.row.data);
        }
    };

    const handleSourceFieldReferenceIdCalculateDisplayValue = (e: any) => {
        let found = sourceFields.find((x) => x.Id === e.SourceFieldReferenceId) ?? null;
        return found?.Name ?? "";
    }

    const handleTransformationTypeCalculateDisplayValue = (e: any) => {
        let found = transformationTypes.find((x) => x.Id === e.TransformationType) ?? null;
        return found?.Label ?? "";
    }

    const handleOnChangesChange = (e: DataChange[]): void => {
        SetMappingChanges(e, mapping);
    }

    const getTagColor = (type: FieldTypeReferenceDto): string => {
        switch (type.Type) {
            case 0:
                return '#5A5A65';
            case FieldType.String:
                return '#00BBEE';
            case 8:
                return '#9A4AFF';
            case 3000:
                return '#0F7DFF';
        }
        return '#9A4AFF';
    }

    const getTextColor = (type: FieldTypeReferenceDto): string => {
        switch (type.Type) {
            case 0:
                return '#9393A2';
            case FieldType.String:
                return 'white';
            case 8:
                return 'white';
            case 3000:
                return 'white';

        }
        return 'white';
    }

    const SourceFieldTypeCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        let synchroFieldMappingDto = cellData.data as SynchroFieldMappingDto;

        let sourceField = sourceFields.find((e) => e.Id === synchroFieldMappingDto.SourceFieldReferenceId) ?? null;

        if (sourceField !== null) {

            let fieldTypeReferenceDto = sourceField.Type
            let tagColor = getTagColor(fieldTypeReferenceDto);
            let textColor = getTextColor(fieldTypeReferenceDto);

            return (
                <div style={{ width: 'auto', height: 22, paddingLeft: 10, paddingRight: 10, paddingTop: 2, paddingBottom: 2, background: tagColor, borderRadius: 16, justifyContent: 'center', alignItems: 'center', gap: 6, display: 'inline-flex' }}>
                    <div style={{ textAlign: 'center', color: textColor, fontSize: 10, fontFamily: 'Manrope', fontWeight: '500', textTransform: 'uppercase', lineHeight: 13, letterSpacing: 1, wordWrap: 'break-word' }}>{fieldTypeReferenceDto.TypeLabel}</div>
                </div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    };

    const selectFieldCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        if (cellData.value > 0) { return (<div>{cellData.displayValue}</div>); }
        return (<span>{cellData.value || <span style={{ color: "#aaa" }}>Select a field...</span>}</span>);
    };

    const ShowTransformationCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {
        let options = TransformationLogic.GetOptionsDetails(cellData.data, sourceFields, targetFields, transformationsCapabilities);
        if (options) {
            try {
                var data = JSON.parse(cellData.data.TransformationParameters);
                if (data) {
                    if (data.Id) {
                        var label = options.Options.find((e) => e.Id === data.Id)?.Label ?? "";
                        return (
                            <div className="date-format-container-show">
                                <label className="date-format-label">
                                    <span className="icon-calendar">&#128197;</span>
                                </label>
                                <span>{label}</span>
                            </div>
                        );
                    }
                }
            }
            catch (e) {
                console.log("ERROR " +e +" "+ cellData.data.TransformationParameters);
            }
        }

        return (
            <Fragment></Fragment>
        );
    };

    const EditTransformationCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {

        let options = TransformationLogic.GetOptionsDetails(cellData.data, sourceFields, targetFields, transformationsCapabilities);
        if (options) {
            return (
                <div className="date-format-container-edit">
                    <label className="date-format-label">
                        <span className="icon-calendar">&#128197;</span>
                    </label>
                    <SelectBox dataSource={options.Options} displayExpr="Label" onSelectionChanged={(e) => cellData.setValue("{ \"Id\" : " + e.selectedItem.Id + " }")}></SelectBox>
                </div>
            );
        }

        return (<div></div>);
    };

return (
    <div className={"BlockCard-FieldGrid"}>
        <DataGrid
            id="dataGrid"
            ref={dataGridRef}
            dataSource={customDataSource}
            columnAutoWidth={false}
            height='calc(100vh - 400px)'
            onEditorPreparing={handleOnEditorPreparing}
        >
            <Scrolling mode="infinite" />
            <FilterRow visible={true} />

            <Editing
                mode="batch"
                allowUpdating={true}
                allowAdding={false}
                allowDeleting={false}
                refreshMode="repaint"
                onChangesChange={handleOnChangesChange}
            />

            <Column caption="SOURCE" cssClass="GridGroupHeader">
                <Column caption="FIELD NAME" dataField="SourceFieldReferenceId" dataType="number" allowEditing={true} calculateDisplayValue={handleSourceFieldReferenceIdCalculateDisplayValue} cellRender={selectFieldCell} editorOptions={{ placeholder: "Select a field..." }}>
                    <Lookup
                        displayExpr="Name"
                        valueExpr="Id"
                    />
                </Column>
                <Column caption="FIELD TYPE" dataField="SourceFieldTypeLabel" dataType="string" allowEditing={false}
                    cellRender={SourceFieldTypeCell}
                    filterOperations={["contains"]}
                />
            </Column>
            <Column caption="TRANSFORMATION" cssClass="GridGroupHeader">
                <Column caption="TYPE" dataField="TransformationType" dataType="number" allowEditing={true} calculateDisplayValue={handleTransformationTypeCalculateDisplayValue}>
                    <Lookup
                        displayExpr="Label"
                        valueExpr="Id"
                    />
                </Column>
                <Column caption="TRANSFORMATION" dataField="TransformationParameters" dataType="string" allowEditing={true} cellRender={ShowTransformationCell} editCellRender={EditTransformationCell} />
            </Column>
            <Column caption="TARGET" cssClass="GridGroupHeader">
                <Column caption="FIELD NAME" dataField="Name" dataType="string" allowEditing={false} />
                <Column caption="FIELD TYPE" dataField="TypeLabel" dataType="string" allowEditing={false}
                    cellRender={TargetFieldTypeCell}
                    filterOperations={["contains"]}
                />
            </Column>
            <Column caption="KEY" dataField="IsKey" dataType="boolean" allowEditing={true} width="100px" cssClass="GridGroupHeader" />
        </DataGrid>
    </div>
);
});

export default FieldGrid;
