class CreateOrUpdateDataSourceAccessResult {

    Id: number;

    constructor(id: number) {
        this.Id = id;
    }
}

export default CreateOrUpdateDataSourceAccessResult;
