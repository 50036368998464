class CustomerAgentListDto {
    Id: number;
    Name: string;
    Token: string;
    Type: number;

    constructor(id: number, name: string, token: string, type: number) {
        this.Id = id;
        this.Name = name;
        this.Token = token;
        this.Type = type;
    }
}

export default CustomerAgentListDto;
