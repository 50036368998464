import React, { useState, FC, ReactNode } from 'react';
import { HttpTransportType, HubConnectionBuilder, HubConnection } from '@microsoft/signalr';

import Settings from "./api/Settings";

import NotificationContext from './NotificationContext';
import UserData from './classes/api/result/accountmodule/UserData';

interface NotificationContextProviderProps {
    currentUserData: UserData;
    children: ReactNode;
}

const NotificationContextProvider: FC<NotificationContextProviderProps> = ({ currentUserData, children }) => {
    const [sharedVariable, setSharedVariable] = useState<string>('Valeur initiale');

    var connection = new HubConnectionBuilder()
        .withUrl(Settings.api_url + "/clientHub", { skipNegotiation: true, transport: HttpTransportType.WebSockets })
        .build();

    const [notificationConnection] = useState<HubConnection>(connection);

    connection.start()
        .then(() => {
            connection.send("Register", currentUserData.UserId).then(() => {
                // Registered
            })
        })
        .catch(err => alert('Error connecting to hub:' + err));

    return (
        <NotificationContext.Provider value={{ sharedVariable, setSharedVariable, notificationConnection }}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationContextProvider;
