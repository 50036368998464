import DashboardRunDto from "./DashboardRunDto";

export default class DashboardLastRunsDto {

    CurrentMonthBegin: Date;
    CurrentMonthEnd: Date;
    CurrentMonthRunsCount: number;
    Runs: DashboardRunDto[];

    constructor(currentMonthBegin: Date, currentMonthEnd: Date, currentMonthRunsCount: number, runs: DashboardRunDto[]) {
        this.CurrentMonthBegin = currentMonthBegin;
        this.CurrentMonthEnd = currentMonthEnd;
        this.CurrentMonthRunsCount = currentMonthRunsCount;
        this.Runs = runs;
    }
}
