import { Dashboard, Paths, Runs, Settings, Explore, HomePage, TasksPage, ProfilePage, PathSynchro, PathReporting } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

export const PathSynchroRoute = "pathsynchro"
export const PathReportingRoute = "pathreporting"

const routes = [
    {
        path: '/dashboard',
        element: Dashboard
    },
    {
        path: '/paths',
        element: Paths
    },
    {
        path: '/runs',
        element: Runs
    },
    {
        path: '/settings',
        element: Settings
    },
    {
        path: '/explore',
        element: Explore
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/' + PathSynchroRoute + '/:id',
        element: PathSynchro
    },
    {
        path: '/' + PathReportingRoute + '/:id',
        element: PathReporting
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
