import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import './Header.scss';
import type { HeaderProps } from '../../types';
import { useAuth } from '../../contexts/auth';

export default function Header({ menuToggleEnabled, title, toggleMenu }: HeaderProps) {
  const { signOut } = useAuth();
  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={'before'}              >
            <img src="svgs/logo.svg" alt="Kitten" height="28" />
        </Item>
        <Item
          location={'after'}>
          <Button
            type="normal"
            stylingMode="outlined"
            icon='./svgs/logout.svg'
            text='Log out'
            onClick={signOut}
          />
        </Item>
      </Toolbar>
    </header>
)}
