import React, { useCallback, useMemo } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, GroupItem, ButtonItem } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';

import './ChangeInProgressForm.scss';

interface ChangeInProgressFormProps {
    IsOpen: boolean;
    SetIsOpen(value: boolean): void;
    Ok(): void;
    Cancel(): void;
}

const ChangeInProgressForm: React.FC<ChangeInProgressFormProps> = ({ IsOpen, SetIsOpen, Ok, Cancel }) => {

    const closeDialog = useCallback(() => {
        SetIsOpen(false);
    }, [SetIsOpen]);

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        }
    }, []);

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'outlined' as ButtonType,
        useSubmitBehavior: false,
        width: '100%',
        onClick: Cancel
    };

    const confirmButtonOptions = {
        text: 'Save as draft',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%',
        onClick: Ok
    };

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={IsOpen}
                onHiding={closeDialog}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title="Unsave changes"
                container=".dx-viewport"
                width={600}
                height='auto'
            >
                <Form colCount={2}>
                    <GroupItem colSpan={2}>
                        Modifications are currently being made.
                    </GroupItem>
                    <GroupItem colSpan={2}>
                        Would you like to cancel or save the changes ?
                    </GroupItem>
                    <GroupItem>
                        <ButtonItem buttonOptions={cancelButtonOptions} />
                    </GroupItem>
                    <GroupItem>
                        <ButtonItem buttonOptions={confirmButtonOptions} />
                    </GroupItem>
                </Form>
            </Popup>
        </React.Fragment>
    );
}

export default ChangeInProgressForm;