import React, { useState, useEffect } from 'react';
import DashboardApi from '../../api/DashboardApi';
import DashboardDetailsDto from '../../classes/dtos/dashboardmodule/DashboardDetailsDto';

import GlobalLoader from './../loader/GlobalLoader';
import DashboardEmpty from './DashboardEmpty';
import DashboardFull from './DashboardFull';

const DashboardContent: React.FC = () => {

    const [dashboardDetails, setDashboardDetails] = useState<DashboardDetailsDto | null>(null);

    useEffect(() => {
        const getDetails = async () => {
            if (dashboardDetails === null) {
                var result = await DashboardApi.GetDetailsAsync();
                if (result.IsSuccess()) {
                    setDashboardDetails(result.Result as DashboardDetailsDto);
                }
            }
        };

        getDetails();
    }, [dashboardDetails]);

    if (dashboardDetails === null) {
        return <GlobalLoader />
    }

    if (dashboardDetails.PathCount > 0) {
        return <DashboardFull Details={dashboardDetails} />;
    } else {
        return <DashboardEmpty />;
    }

}

export default DashboardContent;
