import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import DashboardDetailsDto from "../classes/dtos/dashboardmodule/DashboardDetailsDto";
import DashboardRunDto from "../classes/dtos/dashboardmodule/DashboardRunDto";
import DashboardLastRunsDto from "../classes/dtos/dashboardmodule/DashboardLastRunsDto";
import DashboardConnectorDto from "../classes/dtos/dashboardmodule/DashboardConnectorDto";
import DashboardLastConnectorsDto from "../classes/dtos/dashboardmodule/DashboardLastConnectorsDto";

const DashboardApi = {

    GetDetailsAsync: async function (): Promise<ProcessingResult<DashboardDetailsDto>> {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/Dashboard/GetDetails?customerId=" + customerId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();

            let dashboardLastRunsDto = new DashboardLastRunsDto(data.lastRuns.currentMonthBegin, data.lastRuns.currentMonthEnd, data.lastRuns.currentMonthRunsCount, data.lastRuns.runs.map((e: any) => new DashboardRunDto(e.id, e.name, e.type, e.start, e.status, e.progress)));
            let dashboardLastConnectorsDto = new DashboardLastConnectorsDto(data.lastConnectors.currentMonthNewConnectorsCount, data.lastConnectors.connectors.map((e: any) => new DashboardConnectorDto(e.type, e.name, e.logo)));

            return ProcessingResult.Ok(new DashboardDetailsDto(data.userLabel, data.pathCount, dashboardLastRunsDto, dashboardLastConnectorsDto));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetLastRunsAsync: async function (): Promise<ProcessingResult<DashboardLastRunsDto>> {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/Dashboard/GetLastRuns?customerId=" + customerId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();

            return ProcessingResult.Ok(new DashboardLastRunsDto(data.currentMonthBegin, data.currentMonthEnd, data.currentMonthRunsCount, data.runs.map((e: any) => new DashboardRunDto(e.id, e.name, e.type, e.start, e.status, e.progress))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default DashboardApi;