import React from 'react';

import { LoadIndicator } from 'devextreme-react/load-indicator';

import './GlobalLoader.css';

const GlobalLoader: React.FC = () => {

    return (
        <div className={"loader-container"}>
            <LoadIndicator id="large-indicator" height={60} width={60} />
        </div>);
}

export default GlobalLoader;
