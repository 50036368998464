class PathSynchroMappingCapabilityDto {
    SourceFieldId: number;
    TransformationTypes: number[];

    constructor(
        sourceFieldId: number,
        transformationTypes: number[]
    ) {
        this.SourceFieldId = sourceFieldId;
        this.TransformationTypes = transformationTypes;
    }
}

export default PathSynchroMappingCapabilityDto;
