import React from 'react';

import { DataGrid, Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store'

import LogoCell from './LogoCell';

import DashboardLastConnectorsDto from '../../classes/dtos/dashboardmodule/DashboardLastConnectorsDto';
import DashboardConnectorDto from '../../classes/dtos/dashboardmodule/DashboardConnectorDto';

interface LastConnectorsProps {
    Details: DashboardLastConnectorsDto;
}

const LastConnectors: React.FC<LastConnectorsProps> = ({ Details }) => {

    const customDataSource = new CustomStore({
        key: 'Type',
        load: (): Promise<DashboardConnectorDto[]> => {
            return Promise.resolve(Details.Connectors);
        }
    });

    return (
        <React.Fragment>
            <DataGrid id="dataGridLastConnectors" dataSource={customDataSource} columnAutoWidth={true} >
                <Column caption="NAME" dataField="Logo" cellRender={LogoCell} allowSorting={false} />
                <Column caption="" dataField="Name" dataType="string" allowSorting={false} />
            </DataGrid>
        </React.Fragment>
    );
}

export default LastConnectors;