export default class DashboardRunDto {

    Id: number;
    Name: string;
    Type: string;
    Start: Date;
    Status: number;
    Progress: number;

    constructor(id: number, name: string, type: string, start: Date, status : number, progress: number) {
        this.Id = id;
        this.Name = name;
        this.Type = type;
        this.Start = start;
        this.Status = status;
        this.Progress = progress;
    }
}
