import UserData from "../classes/api/result/accountmodule/UserData";
import Settings from "./Settings";

const UserApi = {

    EnsureStandardAccountAsync: async function (token: string): Promise<UserData> {
        const response = await fetch(Settings.api_url + "/User/EnsureStandardAccount", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });

        if (response.status === 200) {
            var userData = await response.json();
            return new UserData(userData.userId, userData.customerId);
        }

        return new UserData(-1, -1);
    }
};

export default UserApi;