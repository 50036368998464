abstract class TransformationCapabilitiesDto {
    SourceType: number;
    TargetType: number;
    TransformationType: number;
    TransformationCapabilitiesType: string;

    constructor(
        sourceType: number,
        targetType: number,
        transformationType: number,
        transformationCapabilitiesType: string
    ) {
        this.SourceType = sourceType;
        this.TargetType = targetType;
        this.TransformationType = transformationType;
        this.TransformationCapabilitiesType = transformationCapabilitiesType;
    }
}

export default TransformationCapabilitiesDto;
