import React, { useState, useEffect } from 'react';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';

interface TokenBoxProps {
    Token: string;
}

const TokenBox: React.FC<TokenBoxProps> = ({ Token }) => {
    const [text, setText] = useState<string>('');

    useEffect(() => {
        if (Token) {
            setText(Token);
        }
    }, [Token]);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextBox
                value={text}
                width="300px"
                placeholder="Entrez du texte..."
                readOnly={true}
            />
            <Button
                icon="copy"
                onClick={handleCopyClick}
                hint="Copier dans le presse-papiers"
                stylingMode="text"
                width={30}
                style={{ marginLeft: '-40px' }}
            />
        </div>
    );
};

export default TokenBox;
