import DataSourceListDto from "../../../dtos/datasourcemodule/DataSourceListDto";

class GetDataSourcesResult {
    DataSources: DataSourceListDto[];

    constructor(dataSources: DataSourceListDto[]) {
        this.DataSources = dataSources;
    }
}

export default GetDataSourcesResult;
