import FieldReferenceIdAndKeyDto from "../../../dtos/FieldReferenceIdAndKeyDto";

class GetRequestFieldsResult {
    Status: number;
    Fields: FieldReferenceIdAndKeyDto[];

    constructor(status: number, fields: FieldReferenceIdAndKeyDto[]) {
        this.Status = status;
        this.Fields = fields;
    }
}

export default GetRequestFieldsResult;
