import FieldTypeReferenceDto from "../FieldTypeReferenceDto";

class SynchroFieldMappingDto {
    IsKey: boolean;
    SourceFieldReferenceId: number | null;
    TransformationType: number | null;
    TransformationParameters: string | null;
    TargetFieldReferenceId: number;
    Name: string;
    Type: FieldTypeReferenceDto;
    TypeLabel: string;

    constructor(
        isKey: boolean,
        sourceFieldReferenceId: number | null,
        transformationType: number | null,
        transformationParameters: string | null,
        targetFieldReferenceId: number,
        name: string,
        type: FieldTypeReferenceDto
        
    ) {
        this.IsKey = isKey;
        this.SourceFieldReferenceId = sourceFieldReferenceId;
        this.TransformationType = transformationType;
        this.TransformationParameters = transformationParameters;
        this.TargetFieldReferenceId = targetFieldReferenceId;
        this.Name = name;
        this.Type = type;
        this.TypeLabel = type.TypeLabel;
    }
}

export default SynchroFieldMappingDto;
