import CustomerAgentListDto from './../../../dtos/customeragentmodule/CustomerAgentListDto';

class GetCustomerAgentsResult {
    CustomerAgents: CustomerAgentListDto[];

    constructor(customerAgents: CustomerAgentListDto[]) {
        this.CustomerAgents = customerAgents;
    }
}

export default GetCustomerAgentsResult;
