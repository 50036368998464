import PathSynchroMappingCapabilityDto from "./PathSynchroMappingCapabilityDto";

class PathSynchroMappingCapabilitiesDto {
    TargetFieldId: number;
    Capabilities: PathSynchroMappingCapabilityDto[];

    constructor(
        targetFieldId: number,
        capabilities: PathSynchroMappingCapabilityDto[]
    ) {
        this.TargetFieldId = targetFieldId;
        this.Capabilities = capabilities;
    }
}

export default PathSynchroMappingCapabilitiesDto;
