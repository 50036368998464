import { publicClientApplication } from "../index";
import { InteractionRequiredAuthError, AuthenticationResult } from '@azure/msal-browser';
import { apiScopes } from '../AuthConfig';
import AuthSession from './AuthSession';

export class AuthToken
{
    public static async get(): Promise<AuthSession> {
        const redirectResponse = await publicClientApplication.handleRedirectPromise();
        if (redirectResponse !== null) {
            return AuthToken.FillAuthSession(redirectResponse);

        } else {
            const account = publicClientApplication.getAllAccounts()[0];
            const accessTokenRequest = {
                scopes: apiScopes,
                account: account,
            };

            try {
                var accessTokenResponse = await publicClientApplication.acquireTokenSilent(accessTokenRequest);
                return AuthToken.FillAuthSession(accessTokenResponse);
            }
            catch(error) {
                console.log(error);
                if (error instanceof InteractionRequiredAuthError) {
                    publicClientApplication.acquireTokenRedirect(accessTokenRequest);
                }
            }
        }

        return new AuthSession("","");
    }

    private static FillAuthSession(authenticationResult: AuthenticationResult): AuthSession {

        var authSession = new AuthSession(authenticationResult.accessToken, authenticationResult.account.username);

        if (authenticationResult.idTokenClaims.hasOwnProperty("family_name")) {
            authSession.LastName = (authenticationResult.idTokenClaims as any).family_name;
        }

        if (authenticationResult.idTokenClaims.hasOwnProperty("given_name")) {
            authSession.FirstName = (authenticationResult.idTokenClaims as any).given_name;
        }

        // TODO Country

        window.ChatInitialize(authSession.FirstName, authSession.LastName, authSession.Email);

        return authSession;
    }
}