import React from 'react';
import { ProgressBar } from 'devextreme-react/progress-bar';
import { DataGridTypes } from 'devextreme-react/data-grid';

import './ProgressCell.scss';

const ProgressCell = (cellData: DataGridTypes.ColumnCellTemplateData) => (
    <ProgressBar min={0} max={100} value={cellData.value} className={'complete'} />
);

export default ProgressCell;
