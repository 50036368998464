import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetDataSourceCredentialsResult from "../classes/api/result/datasourcemodule/GetDataSourceCredentialsResult";
import CreateOrUpdateDataSourceAccessResult from "../classes/api/result/datasourcemodule/CreateOrUpdateDataSourceAccessResult";
import CreateRequestResult from "../classes/api/result/CreateRequestResult";
import GetRequestTestDataSourceCredentialResult from "../classes/api/result/datasourcemodule/GetRequestTestDataSourceCredentialResult";

import DataSourceCredentialListDto from "../classes/dtos/datasourcemodule/DataSourceCredentialListDto";
import DataSourceAccessDto from "../classes/dtos/datasourcemodule/DataSourceAccessDto";

const DataSourceCredentialApi = {

    GetDataSourceCredentialsByCustomerAsync: async function (dataSourceType: number): Promise<ProcessingResult<GetDataSourceCredentialsResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSourceCredential/GetDataSourceCredentialsByCustomer?customerId=" + customerId.toString() + "&dataSourceType=" + dataSourceType, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetDataSourceCredentialsResult(data.dataSourceCredentials.map((e: any) => new DataSourceCredentialListDto(e.id, e.name))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateDataSourceAccessAsync: async function (name: string, dataSourceType: number, parameters: object): Promise<ProcessingResult<CreateOrUpdateDataSourceAccessResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSourceCredential/CreateDataSourceAccess", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId': customerId, 'dataSourceAccess': { 'name': name, 'DataSourceType': dataSourceType, 'parameters': parameters } })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateOrUpdateDataSourceAccessResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    UpdateDataSourceAccessAsync: async function (id:number, name: string, parameters: object): Promise<ProcessingResult<CreateOrUpdateDataSourceAccessResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSourceCredential/UpdateDataSourceAccess", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId': customerId, 'dataSourceAccess': { 'id': id, 'name': name, 'parameters': parameters } })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateOrUpdateDataSourceAccessResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetDataSourceAccessByIdAsync: async function (id: number): Promise<ProcessingResult<DataSourceAccessDto>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSourceCredential/GetDataSourceAccessById?customerId=" + customerId.toString() + "&dataSourceAccessId=" + id, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new DataSourceAccessDto(data.access.id, data.access.name, data.access.dataSourceType, data.access.parameters));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateRequestTestDataSourceCredentialAsync: async function (dataSourceAccessId: number): Promise<ProcessingResult<CreateRequestResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSourceCredential/CreateRequestTestDataSourceCredential", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSourceAccessId': dataSourceAccessId
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateRequestResult(data.accepted, data.token));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetRequestTestDataSourceCredentialAsync: async function (taskId: string): Promise<ProcessingResult<GetRequestTestDataSourceCredentialResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSourceCredential/GetRequestTestDataSourceCredential?CustomerId=" + customerId + "&TaskId=" + taskId, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetRequestTestDataSourceCredentialResult(data.status, data.ok, data.error));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default DataSourceCredentialApi;