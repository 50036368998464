import ProcessingResult from "../../framework/ProcessingResult";

import Delay from "../../tools/Delay";

import DataSourceApi from "../../api/DataSourceApi";
import DataStructureApi from "../../api/DataStructureApi";

import GetTableReferencesResult from "../../classes/logics/GetTableReferencesResult";

import { StatusUnavailable, StatusLive, StatusError, StatusTimeout, StatusNoData, StatusCache } from "../../classes/consts/JobStatus";

const TableReferenceLogic = {

    GetTableReferencesAsync: async function (dataSourceId: number, databaseReferenceId: number, withUpdate: boolean): Promise<ProcessingResult<GetTableReferencesResult>> {

        if (withUpdate) {
            var createRequestTablesResult = await DataSourceApi.CreateRequestTablesAsync(dataSourceId, databaseReferenceId);

            if (createRequestTablesResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateRequestTablesAsync " + createRequestTablesResult.ErrorMessage);
            }

            if (createRequestTablesResult.Result.Accepted) {
                var token = createRequestTablesResult.Result.Token;
                for (let i = 0; i < 20; i++) {
                    var getRequestTablesResult = await DataSourceApi.GetRequestTablesAsync(token)

                    if (getRequestTablesResult.IsFailed()) {
                        return ProcessingResult.Error("Error in GetRequestTablesAsync " + getRequestTablesResult.ErrorMessage);
                    }

                    if (getRequestTablesResult.Result.Status === 4) {
                        return ProcessingResult.Ok(new GetTableReferencesResult(StatusLive, getRequestTablesResult.Result.Tables));
                    }

                    if (getRequestTablesResult.Result.Status === 3) {
                        return ProcessingResult.Ok(new GetTableReferencesResult(StatusError, []));
                    }

                    await Delay.Sleep(500)
                }
                return ProcessingResult.Ok(new GetTableReferencesResult(StatusTimeout, []));
            }
        }

        let getTableReferencesResult = await DataStructureApi.GetTableReferencesAsync(databaseReferenceId);

        if (getTableReferencesResult.IsFailed()) {
            return ProcessingResult.Error("Error in GetTableReferencesAsync " + getTableReferencesResult.ErrorMessage);
        }

        if (getTableReferencesResult.Result.LastUpdateDate == null) {
            return ProcessingResult.Ok(new GetTableReferencesResult(StatusNoData, []));
        }
        else {
            if (!withUpdate) {
                return ProcessingResult.Ok(new GetTableReferencesResult(StatusCache, getTableReferencesResult.Result.Tables));
            } else {
                return ProcessingResult.Ok(new GetTableReferencesResult(StatusUnavailable, getTableReferencesResult.Result.Tables));
            }
        }
    }
};

export default TableReferenceLogic;