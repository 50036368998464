import DashboardLastRunsDto from "./DashboardLastRunsDto";
import DashboardLastConnectorsDto from "./DashboardLastConnectorsDto";

export default class DashboardDetailsDto {

    UserLabel: string;
    PathCount: number;
    LastRuns: DashboardLastRunsDto;
    LastConnectors: DashboardLastConnectorsDto;

    constructor(userLabel: string, pathCount: number, lastRuns: DashboardLastRunsDto, lastConnectors: DashboardLastConnectorsDto) {
        this.UserLabel = userLabel;
        this.PathCount = pathCount;
        this.LastRuns = lastRuns;
        this.LastConnectors = lastConnectors;
    }
}
