import React, { useCallback, useMemo } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, GroupItem, ButtonItem } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';

import './DataSourceErrorForm.scss';

interface DataSourceErrorFormProps {
    IsOpen: boolean;
    SetIsOpen(value: boolean): void;
}

const DataSourceErrorForm: React.FC<DataSourceErrorFormProps> = ({ IsOpen, SetIsOpen }) => {

    const closeDialog = useCallback(() => {
        SetIsOpen(false);
    }, [SetIsOpen]);

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        }
    }, []);

    const close = () => {
        SetIsOpen(false);
    }

    const confirmButtonOptions = {
        text: 'Ok',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%',
        onClick: close
    };
    
    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={IsOpen}
                onHiding={closeDialog}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title="Information"
                container=".dx-viewport"
                width={400}
                height='auto'
            >
                <Form>
                    <GroupItem>
                        Data source credentials invalid !
                    </GroupItem>
                    <GroupItem>
                        <ButtonItem buttonOptions={confirmButtonOptions} />
                    </GroupItem>
                </Form>
            </Popup>
        </React.Fragment>
    );
}

export default DataSourceErrorForm;