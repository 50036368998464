export const navigation = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard'
  },
  {
    text: 'Paths',
    path: '/paths',
    icon: 'paths'
  },
  {
    text: 'Runs',
    path: '/runs',
    icon: 'runs'
  },
  {
    text: 'Settings',
    path: '/settings',
    icon: 'settings'
  },
  {
    text: 'Explore',
    path: '/explore',
    icon: 'explore'
  }
  ];
