import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from 'devextreme/core/config';

import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, RedirectRequest, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";

import { msalConfig, apiScopes } from './AuthConfig';

import { licenseKey } from './devextreme-license';

config({ licenseKey });

export const publicClientApplication = new PublicClientApplication(msalConfig);

publicClientApplication.initialize().then(() => {
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    const loginRequest: RedirectRequest = {
        scopes: apiScopes
    };
    
    root.render(
        <MsalProvider instance={publicClientApplication}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} >
                <App />
            </MsalAuthenticationTemplate>
        </MsalProvider>);

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
});
