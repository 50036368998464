import FieldReferenceIdAndKeyDto from "../../../dtos/FieldReferenceIdAndKeyDto";

class UpsertFieldReferencesResult {
    LastUpdateDate: Date;
    Fields: FieldReferenceIdAndKeyDto[];

    constructor(lastUpdateDate: Date, fields: FieldReferenceIdAndKeyDto[]) {
        this.LastUpdateDate = lastUpdateDate;
        this.Fields = fields;
    }
}

export default UpsertFieldReferencesResult;
