class TransformationOptionDto {
    Id: number;
    Label: string;

    constructor(
        id: number,
        label: string
    ) {
        this.Id = id;
        this.Label = label;
    }
}

export default TransformationOptionDto;
