import TransformationCapabilitiesDto from "./TransformationCapabilitiesDto";
import TransformationOptionDto from "./TransformationOptionDto";

class TransformationOptionsDto extends TransformationCapabilitiesDto {
    Options: TransformationOptionDto[];

    constructor(
        sourceType: number,
        targetType: number,
        transformationType: number,
        transformationCapabilitiesType: string,
        options: TransformationOptionDto[]
    ) {
        super(sourceType, targetType, transformationType, transformationCapabilitiesType);
        this.Options = options;
    }
}

export default TransformationOptionsDto;
