import DataSourceCredentialListDto from "../../../dtos/datasourcemodule/DataSourceCredentialListDto";

class GetDataSourceCredentialsResult {

    DataSourceCredentials: DataSourceCredentialListDto[];

    constructor(dataSourceCredentials: DataSourceCredentialListDto[]) {
        this.DataSourceCredentials = dataSourceCredentials;
    }
}

export default GetDataSourceCredentialsResult;
