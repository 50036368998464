export default class DashboardConnectorDto {

    Type: number;
    Name: string;
    Logo: string;

    constructor(type: number, name: string, logo: string) {
        this.Type = type;
        this.Name = name;
        this.Logo = logo;
    }
}
