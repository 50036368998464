import PathReportingMappingDto from "../../../dtos/pathreportingmodule/PathReportingMappingDto";
import ScheduleDto from "../../../dtos/schedulermodule/ScheduleDto";
import TableMappingReadDto from "../../../dtos/pathcommonmodule/TableMappingReadDto";

class GetPathReportingDetailResult {
    Id: number;
    Name: string;
    Source: TableMappingReadDto | null;
    Mappings: PathReportingMappingDto[];
    Schedule: ScheduleDto;

    constructor(id: number, name: string, source: TableMappingReadDto | null, mappings: PathReportingMappingDto[], schedule: ScheduleDto) {
        this.Id = id;
        this.Name = name;
        this.Source = source;
        this.Mappings = mappings;
        this.Schedule = schedule;
    }
}

export default GetPathReportingDetailResult;
