import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import { DataChange } from 'devextreme/common/grids';

import FieldGrid, { FieldGridHandle } from './FieldGrid';

import TableMappingReadDto from '../../classes/dtos/pathcommonmodule/TableMappingReadDto';
import PathSynchroMappingDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingDto';

import './SynchroMappingFields.css';

interface SynchroMappingFieldsProps {
    Source: TableMappingReadDto | null;
    Target: TableMappingReadDto | null;
    Mappings: PathSynchroMappingDto[];
    MappingChanges: DataChange[] | undefined;
    SetMappingChanges(changes: DataChange[], origin: SynchroFieldMappingDto[]): void;
}

export interface SynchroMappingFieldsHandle {
    Save: () => void;
}

const SynchroMappingFields: React.FC<SynchroMappingFieldsProps> = forwardRef<SynchroMappingFieldsHandle, SynchroMappingFieldsProps>(({ Source, Target, Mappings, MappingChanges, SetMappingChanges }, ref) => {

    useImperativeHandle(ref, () => ({
        Save() {
            if (fieldGridRef.current) {
                fieldGridRef.current.Save();
            }
        }
    }));

    const fieldGridRef = useRef<FieldGridHandle>(null);

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '100%', position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                <div style={{ top: 10, position: 'absolute', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <FieldGrid ref={fieldGridRef} SourceDataSourceId={Source?.DataSourceId ?? 0} SourceTableReferenceId={Source?.TableReferenceId ?? 0} TargetDataSourceId={Target?.DataSourceId ?? 0} TargetTableReferenceId={Target?.TableReferenceId ?? 0} Mappings={Mappings} MappingChanges={MappingChanges} SetMappingChanges={SetMappingChanges} />
                </div>
            </div>
        </React.Fragment>
    );
});

export default SynchroMappingFields;