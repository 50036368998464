import React, { useCallback, useState, useEffect, useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, Label, FormRef, GroupItem, SimpleItem, ButtonItem, EmptyItem, RequiredRule } from 'devextreme-react/form';
import { ButtonType, ButtonStyle } from 'devextreme-react/common';
import 'devextreme-react/text-area';

import LabelTemplate from './LabelTemplate';

import PathApi from "../../api/PathApi";
import PathTypeApi from "../../api/PathTypeApi";

import PathTypeListDto from "../../classes/dtos/pathmodule/PathTypeListDto";
import PathCreationDto from '../../classes/dtos/pathmodule/PathCreationDto';

import './PathCreationForm.scss';

interface PathCreationFormProps {
    Created(pathId: number, pathTypeId: number): void;
}

export interface PathCreationFormHandle {
    Open: () => void;
}

const pathCreationDto = new PathCreationDto(null, null);

const PathCreationForm: React.FC<PathCreationFormProps> = forwardRef<PathCreationFormHandle, PathCreationFormProps>(({ Created }, ref) => {

    const formRef = useRef<FormRef>(null);

    const [pathTypes, setPathTypes] = useState<PathTypeListDto[] | null>(null);

    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        Open() {
            if (formRef.current !== null) {
                const formInstance = formRef.current.instance();
                formInstance.clear();
            }
            setIsOpen(true);
        }
    }));

    const nameEditorOptions = {
        valueChangeEvent: 'keyup'
    };

    const typeEditorOptions = {
        items: pathTypes,
        searchEnabled: false,
        displayExpr: "Name",
        valueExpr: "Id"
    };

    useEffect(() => {
        if (!pathTypes) {
            PathTypeApi.GetPathTypesAsync()
                .then(allPathTypes => {
                    setPathTypes(allPathTypes.Result as PathTypeListDto[]);
                });
        }
    }, [pathTypes]);

    const closeDialog = () => {
        setIsOpen(false);
    };

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        }
    }, []);

    const handleSubmit = useCallback(async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        var createPathResult = await PathApi.CreatePathAsync(pathCreationDto.Type ?? 0, pathCreationDto.Name ?? "");
        if (createPathResult.IsFailed()) {
            alert(createPathResult.ErrorMessage);
            return;
        }
        Created(createPathResult.Result.Id, pathCreationDto.Type ?? 0);
        setIsOpen(false);
    }, []);

    const createButtonOptions = {
        text: 'Create path',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%',
        icon: 'plus'
    };

    const tutorialButtonOptions = {
        text: 'Watch the tutorial',
        type: 'normal' as ButtonType,
        stylingMode: 'outlined' as ButtonStyle,
        useSubmitBehavior: false,
        width: '100%'
    };

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={isOpen}
                onHiding={closeDialog}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title="Lets create your path !"
                container=".dx-viewport"
                width={600}
                height={480}
            >
                <form onSubmit={handleSubmit}>
                    <div style={{ width: 480, color: '#9393A2', fontSize: 16, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>To go further, please assign a name and type to your path.</div>
                    <Form
                        ref={formRef}
                        formData={pathCreationDto}
                        showColonAfterLabel={true}
                        showValidationSummary={false}
                        validationGroup="pathCreationDtoData"
                    >
                        <GroupItem>
                            <SimpleItem dataField="Name" editorType="dxTextBox" editorOptions={nameEditorOptions}>
                                <Label render={LabelTemplate('rename')} />
                                <RequiredRule message="Name is required." />
                            </SimpleItem>
                            <SimpleItem dataField="Type" editorType="dxSelectBox" editorOptions={typeEditorOptions}>
                                <Label render={LabelTemplate('product')} />
                                <RequiredRule message="Type is required." />
                            </SimpleItem>
                        </GroupItem>
                        <EmptyItem colSpan={2} />
                        <GroupItem>
                            <ButtonItem buttonOptions={createButtonOptions} />
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={tutorialButtonOptions} />
                        </GroupItem>
                    </Form>
                </form>
            </Popup>
        </React.Fragment>
    );
});

export default PathCreationForm;