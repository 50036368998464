import ProcessingResult from "../../framework/ProcessingResult";

import Delay from "../../tools/Delay";

import DataSourceApi from "../../api/DataSourceApi";
import DataStructureApi from "../../api/DataStructureApi";

import GetFieldReferencesResult from "../../classes/logics/GetFieldReferencesResult";

import { StatusUnavailable, StatusLive, StatusError, StatusTimeout, StatusNoData, StatusCache } from "../../classes/consts/JobStatus";

const FieldReferenceLogic = {

    GetFieldReferencesAsync: async function (dataSourceId: number, tableReferenceId: number, withUpdate: boolean): Promise<ProcessingResult<GetFieldReferencesResult>> {

        if (withUpdate) {
            let createRequestFieldsResult = await DataSourceApi.CreateRequestFieldsAsync(dataSourceId, tableReferenceId);

            if (createRequestFieldsResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateRequestFieldsAsync " + createRequestFieldsResult.ErrorMessage);
            }

            if (createRequestFieldsResult.Result.Accepted) {
                let token = createRequestFieldsResult.Result.Token;
                for (let i = 0; i < 20; i++) {
                    var getRequestFieldsResult = await DataSourceApi.GetRequestFieldsAsync(token)

                    if (getRequestFieldsResult.IsFailed()) {
                        return ProcessingResult.Error("Error in GetRequestFieldsAsync " + getRequestFieldsResult.ErrorMessage);
                    }

                    if (getRequestFieldsResult.Result.Status === 4) {
                        return ProcessingResult.Ok(new GetFieldReferencesResult(StatusLive, getRequestFieldsResult.Result.Fields));
                    }

                    if (getRequestFieldsResult.Result.Status === 3) {
                        return ProcessingResult.Ok(new GetFieldReferencesResult(StatusError, []));
                    }

                    await Delay.Sleep(500)
                }
                return ProcessingResult.Ok(new GetFieldReferencesResult(StatusTimeout, []));
            }
        }

        let getFieldReferencesResult = await DataStructureApi.GetFieldReferencesAsync(tableReferenceId);

        if (getFieldReferencesResult.IsFailed()) {
            return ProcessingResult.Error("Error in GetFieldReferencesAsync " + getFieldReferencesResult.ErrorMessage);
        }

        if (getFieldReferencesResult.Result.LastUpdateDate == null) {
            return ProcessingResult.Ok(new GetFieldReferencesResult(StatusNoData, []));
        }
        else {
            if (!withUpdate) {
                return ProcessingResult.Ok(new GetFieldReferencesResult(StatusCache, getFieldReferencesResult.Result.Fields));
            } else {
                return ProcessingResult.Ok(new GetFieldReferencesResult(StatusUnavailable, getFieldReferencesResult.Result.Fields))
            }
        }
    }
};

export default FieldReferenceLogic;