import FieldTypeReferenceDto from "../FieldTypeReferenceDto";

class ReportingFieldMappingDto {
    SourceFieldReferenceId: number;
    IsExposed: boolean;
    Name: string;
    Type: FieldTypeReferenceDto;

    constructor(
        sourceFieldReferenceId: number,
        isExposed: boolean,
        name: string,
        type: FieldTypeReferenceDto
        
    ) {
        this.SourceFieldReferenceId = sourceFieldReferenceId;
        this.IsExposed = isExposed;
        this.Name = name;
        this.Type = type;
    }
}

export default ReportingFieldMappingDto;
