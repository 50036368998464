import DataSourceAuthenticationDetailDto from './DataSourceAuthenticationDetailDto';

class DataSourceAuthenticationDto {

    Label: string;
    Key: string;
    Details: DataSourceAuthenticationDetailDto[];

    constructor(label: string, key: string, details: DataSourceAuthenticationDetailDto[]) {
        this.Label = label;
        this.Key = key;
        this.Details = details;
    }
}

export default DataSourceAuthenticationDto;