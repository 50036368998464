import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from 'devextreme-react';

import PathApi from '../../api/PathApi';

import PathGrid from '../../components/path-grid/PathGrid';
import PathListDto from '../../classes/dtos/pathmodule/PathListDto';

import PathCreationForm, { PathCreationFormHandle } from '../../components/path-creation-form/PathCreationForm';

import PathType from '../../consts/pathmodule/PathType';

import { PathSynchroRoute, PathReportingRoute } from '../../app-routes';

import './paths.scss';

export default function Paths(props: { openPath: (pathId: number, pathTypeId: number) => void }) {

    var navigate = useNavigate();

    const pathCreationFormHandleRef = useRef<PathCreationFormHandle>(null);

    // States
    const [paths, setPaths] = useState<Array<PathListDto> | null>(null);
    //const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [listToRefresh, setListToRefresh] = useState<boolean>(false);

    // Fetch paths from API
    const refresh = useCallback(() => {
        if (!paths || listToRefresh) {
            PathApi.GetPathsAsync().then((allPaths: any) => {
                if (allPaths.IsFailed()) {
                    // TODO: Handle failure case (e.g., logout)
                } else {
                    setPaths(allPaths.Result);
                    setListToRefresh(false);
                }
            });
        }
    }, [paths, listToRefresh]);

    // UseEffect to refresh when component mounts
    useEffect(() => {
        refresh();
    }, [refresh]);

    function createPath(): void {
        if (pathCreationFormHandleRef.current !== null) {
            pathCreationFormHandleRef.current.Open();
        }
    };

    function openPath(pathId: number, pathTypeId: number) {
        switch (pathTypeId) {
            case PathType.DataSynchronization:
                navigate("/" + PathSynchroRoute + "/" + pathId);
                break;
            case PathType.Reporting:
                navigate("/" + PathReportingRoute + "/" + pathId);
                break;
            default:
        }
    }

    const deletePath = (id: number) => {
        PathApi.DeletePathAsync(id).then((e) => {
            if (e.IsFailed()) {
                alert(e.ErrorMessage);
            }
        });
    };

    return (
        <React.Fragment>
            <div className={"Frame_18_2093_2751"}>
                <div className={"Frame_34_2093_2752"}>
                    <h2>{"All your paths"}</h2>
                    <Button icon="plus" width={140} text="Create path" type="default" stylingMode="contained" onClick={createPath} />
                </div>
                <PathGrid paths={paths} openPath={openPath} deletePath={deletePath} />
            </div>
            <PathCreationForm ref={pathCreationFormHandleRef} Created={openPath} />
        </React.Fragment >
    );
}
