import DatabaseReferenceIdAndKeyDto from "../../../dtos/DatabaseReferenceIdAndKeyDto";

class UpsertDatabaseReferencesResult {
    LastUpdateDate: Date;
    Databases: DatabaseReferenceIdAndKeyDto[];

    constructor(lastUpdateDate: Date, databases: DatabaseReferenceIdAndKeyDto[]) {
        this.LastUpdateDate = lastUpdateDate;
        this.Databases = databases;
    }
}

export default UpsertDatabaseReferencesResult;
