import React, { useCallback, useState, useEffect, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import { ValidationRule } from 'devextreme-react/common';
import { Popup } from 'devextreme-react/popup';
import { Form, Label, FormRef, GroupItem, SimpleItem, ButtonItem } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';
import 'devextreme-react/text-area';

import LabelTemplate from './LabelTemplate';

import TableReferenceLogic from "./../../logics/datastructuremodule/TableReferenceLogic";

import TableReferenceIdAndKeyDto from '../../classes/dtos/TableReferenceIdAndKeyDto';

import TableSelectionDto from '../../classes/dtos/datastructuremodule/TableSelectionDto';

import './TableSelectionForm.scss';

interface TableSelectionFormProps {
    DataSourceId: number;
    DatabaseId: number;
    SetTableSelected(value: TableSelectionDto): void;
}

export interface TableSelectionFormHandle {
    Open: (tableReferenceId: number) => void;
}

const TableSelectionForm: React.FC<TableSelectionFormProps> = forwardRef<TableSelectionFormHandle, TableSelectionFormProps>(({ DataSourceId, DatabaseId, SetTableSelected }, ref) => {

    const [tableSelectionDto, setTableSelectionDto] = useState<TableSelectionDto>(new TableSelectionDto(null));

    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        Open(tableReferenceId: number) {
            if (tables !== null) {
                let current = tables.find((e) => e.Id === tableReferenceId) ?? null;
                setTableSelectionDto(new TableSelectionDto(current));
            }
            setIsOpen(true);
        }
    }));

    const formRef = useRef<FormRef>(null);

    const [tables, setTables] = useState<TableReferenceIdAndKeyDto[] | null>(null);

    const tableEditorOptions = {
        items: tables,
        searchEnabled: false,
        displayExpr: "Name"
    };

    const tablesLoad = useCallback(async () => {

        if (DataSourceId > 0 && DatabaseId > 0) {
            TableReferenceLogic.GetTableReferencesAsync(DataSourceId, DatabaseId, true)
                .then(getTableReferencesResult => {
                    var allTables = getTableReferencesResult.Result.Tables
                        .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);
                    setTables(allTables);
                });
        }
    }, [DataSourceId, DatabaseId]);

    useEffect(() => {
        tablesLoad()
    }, [tablesLoad]);

    const validationRules: {
        table: ValidationRule[]
    } = {
        table: [
            { type: 'required', message: 'Table is required.' },
        ]
    };

    function cancel(): void {
        setIsOpen(false);
    };

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        }
    }, []);

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        SetTableSelected(tableSelectionDto);
        setIsOpen(false);
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'outlined' as ButtonType,
        useSubmitBehavior: false,
        width: '100%',
        onClick: cancel
    };

    const confirmButtonOptions = {
        text: 'Confirm',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%'
    };

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={isOpen}
                onHiding={cancel}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title="Choose your table"
                container=".dx-viewport"
                width={600}
                height="auto"
            >
                <form onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={tableSelectionDto}
                        showColonAfterLabel={true}
                        showValidationSummary={false}
                        validationGroup="tableSelectionDtoData"
                        colCount={2}
                    >
                        <GroupItem colSpan={2}>
                            <SimpleItem dataField="Table" editorType="dxSelectBox" editorOptions={tableEditorOptions} validationRules={validationRules.table}>
                                <Label render={LabelTemplate('product')} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem colSpan={2}>
                            <div style={{ width: '100%', height: 36, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                    <div style={{ color: '#9393A2', fontSize: 10, fontFamily: 'Manrope', fontWeight: '600', textTransform: 'uppercase', lineHeight: '13px', letterSpacing: 1, wordWrap: 'break-word' }}>OR</div>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                </div>
                            </div>
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={cancelButtonOptions} />
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={confirmButtonOptions} />
                        </GroupItem>
                    </Form>
                </form>
            </Popup>
        </React.Fragment>
    );
});

export default TableSelectionForm;