import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'devextreme-react';
import Box, { Item } from 'devextreme-react/box';

import LastRuns from '../last-runs/LastRuns';
import LastConnectors from '../last-connectors/LastConnectors';
import PathCreationForm, { PathCreationFormHandle } from '../path-creation-form/PathCreationForm';

import PathType from '../../consts/pathmodule/PathType';

import { PathSynchroRoute, PathReportingRoute } from '../../app-routes';

import DashboardDetailsDto from '../../classes/dtos/dashboardmodule/DashboardDetailsDto';

import './DashboardFull.css';

interface DashboardFullProps {
    Details: DashboardDetailsDto;
}

const DashboardFull: React.FC<DashboardFullProps> = ({ Details }) => {

    const navigate = useNavigate();

    const pathCreationFormHandleRef = useRef<PathCreationFormHandle>(null);

    function createPath(): void {
        if (pathCreationFormHandleRef.current !== null) {
            pathCreationFormHandleRef.current.Open();
        }
    };

    function goToPaths(): void {
        navigate("/paths");
    };

    function created(pathId: number, pathTypeId: number): void {
        switch (pathTypeId) {
            case PathType.DataSynchronization:
                navigate("/" + PathSynchroRoute + "/" + pathId);
                break;
            case PathType.Reporting:
                navigate("/" + PathReportingRoute + "/" + pathId);
                break;
            default:
        }
    };

    return (
        <React.Fragment>
            <div className={"Frame_18_2093_2751"}>
                <div className={"Frame_34_2093_2752"}>
                    <h2>{"Welcome " + Details.UserLabel + " !"}</h2>
                    <Button icon="plus" width={140} text="Create path" type="default" stylingMode="contained" onClick={createPath} />
                </div>
                <Box direction="row" width="100%">
                    <Item ratio={2}>
                        <div style={{ margin: '10px' }}>
                            <div className={"BlockCard"}>
                                <div className={"BlockHeader"}>
                                    <div className={"BlockTitle"}>
                                        <div style={{ color: '#E3E3E8', fontSize: 18, fontFamily: 'Manrope', fontWeight: '600', letterSpacing: 0.36, wordWrap: 'break-word' }}>Latest paths</div>
                                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 5, display: 'inline-flex' }}>
                                            <img src="svgs/circle.svg" alt="circle"></img>
                                            <div style={{ color: '#9393A2', fontSize: 12, fontFamily: 'Manrope', fontWeight: '500', letterSpacing: 0.24, wordWrap: 'break-word' }}>{Details.LastRuns.CurrentMonthRunsCount} done this month</div>
                                        </div>
                                    </div>
                                    <Button type="normal" stylingMode="outlined" icon='./svgs/rightup.svg' text='Go to paths' onClick={goToPaths} />
                                </div>
                                <LastRuns Details={Details.LastRuns} />
                            </div>
                        </div>
                    </Item>
                    <Item ratio={1}>
                        <div style={{ margin: '10px' }}>
                            <div className={"BlockCard"}>
                                <div className={"BlockHeader"}>
                                    <div className={"BlockTitle"}>
                                        <div style={{ color: '#E3E3E8', fontSize: 18, fontFamily: 'Manrope', fontWeight: '600', letterSpacing: 0.36, wordWrap: 'break-word' }}>Latest connectors</div>
                                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 5, display: 'inline-flex' }}>
                                            <img src="svgs/circle.svg" alt="circle"></img>
                                            <div style={{ color: '#9393A2', fontSize: 12, fontFamily: 'Manrope', fontWeight: '500', letterSpacing: 0.24, wordWrap: 'break-word' }}>Already {Details.LastConnectors.CurrentMonthNewConnectorsCount} newcomers this month</div>
                                        </div>
                                    </div>
                                </div>
                                <LastConnectors Details={Details.LastConnectors} />
                            </div>
                        </div>
                    </Item>
                </Box>
            </div>
            <PathCreationForm ref={pathCreationFormHandleRef} Created={created} />
        </React.Fragment>
    );
}

export default DashboardFull;