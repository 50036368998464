import DashboardConnectorDto from "./DashboardConnectorDto";

export default class DashboardLastConnectorsDto {

    CurrentMonthNewConnectorsCount: number;
    Connectors: DashboardConnectorDto[];

    constructor(currentMonthNewConnectorsCount: number, connectors: DashboardConnectorDto[]) {
        this.CurrentMonthNewConnectorsCount = currentMonthNewConnectorsCount;
        this.Connectors = connectors;
    }
}
